/* Component Dependencies */
var mmnAdsTemplate = require('templates/mmnAds.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'mmnAdsTemplate',
  template: {
    'mmnAdsTemplate': mmnAdsTemplate
  }
});
